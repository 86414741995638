<template>
  <div class="flex-center setup-content-left" @click="changeReadFlag">
    <div
      v-show="showFlag"
      class="setup-content-left-white"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    showFlag: {
      type: Boolean,
      default: false
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    changeReadFlag(){
      this.$emit('changeReadFlag')
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.setup-content-left {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #ddd;
  margin-right: 5px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  .setup-content-left-white {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
  }
}
</style>
