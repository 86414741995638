<template>
  <div class="code-login">
    <van-field
      class="transpranent-input"
      placeholder="请输入手机号"
      type="digit"
      v-model="phoneNumber"
      maxlength="11"
      @blur="blurPhone"
      :readonly="codeContent=='获取验证码'?false:true"
    />
    <div class="get-code">
      <van-field
        class="transpranent-input"
        type="digit"
        maxlength="6"
        placeholder="输入验证码"
        v-model="code"
      />
      <div class="code-text" @click="getCode">
        {{codeContent}}{{codeContent!='获取验证码'?'s后重发':''}}
      </div>
    </div>
    <van-popup
      v-model="visible"
      class="prompt-box"
      :closeable="true"
      @close="closeVisble"
      :round="true"
      :close-on-click-overlay="false"
    >
      <h3>您的手机号尚未注册，请先去注册</h3>
      <van-button
        class="go-reg"
        @click="toReg"
      >去注册</van-button>
    </van-popup>
    <van-button class="login-register-btn" @click="login">登录</van-button>
    <div class="read-agreement">
      <Radio :showFlag="readFlag" @changeReadFlag="changeReadFlag" />已阅读并同意<a href="https://test-bebot-web.baoxianxia.com.cn/#/bebotAgree">《服务协议》</a>及<a href="https://h5.baoxianxia.com.cn/app/secret.html">《隐私政策》</a>
    </div>
    <div class="bottom-jump">
      <span @click="toPage('/register-login/pwdLogin')">密码登录</span>
      <span class="line"></span>
      <span @click="toPage('/register-login/register?type=reg')">账号注册</span>
    </div>
  </div>
</template>

<script>
import Radio from '@/components/Radio'
export default {
  components: {
    Radio
  },
  data() {
    return {
      phoneNumber: null,
      code: null,
      codeContent: '获取验证码',
      havePhoneNum: '17600887719',//有的手机号
      visible: false,
      readFlag: false,
    }
  },
  methods: {
    blurPhone(){
       if(!(/^1[3456789]\d{9}$/.test(this.phoneNumber)) && this.phoneNumber){ 
        this.$toast("手机号码有误，请重填");  
        return false; 
      }
    },
    getCode(){
      // 手机号&&手机类型
      if(!this.phoneNumber){
        this.$toast('请输入手机号');
        return;
      }
      if(!(/^1[3456789]\d{9}$/.test(this.phoneNumber))){
        this.$toast('请输入正确手机号');
        return;
      }
      if((this.phoneNumber != this.havePhoneNum)){
        this.visible = true;
        return;
      }
      this.codeContent = 60;
      this.codeContentTimer2 = setInterval(()=>{
        this.codeContent = this.codeContent - 1;
        if(this.codeContent < 1){
          clearInterval(this.codeContentTimer2)
          this.codeContent = '获取验证码';
        }
      },1000)
    },
    toReg(){
      this.$router.push('/register-login/register')
    },
    closeVisble(){
      this.visible = false;
    },
    toPage(path){
      this.$router.push(path)
    },
    changeReadFlag() {
      this.readFlag = !this.readFlag;
    },
    login(){
      // 手机号&&手机类型
      if(!this.phoneNumber){
        this.$toast('请输入手机号');
        return;
      }
      if(!this.code) {
        this.$toast('请输入验证码');
        return;
      }
      if(!this.readFlag){
        this.$toast('请同意协议');
        return;
      }
      this.$router.replace('/home')
    },
  },
  destroyed() {
    clearInterval(this.codeContentTimer2)
  },
};
</script>

<style lang="scss" scoped>
.code-login {
  width: 84%;
  margin: 0 auto;
  padding-top: 4.45rem;
  overflow: hidden;
  .get-code{
    position: relative;
    .code-text{
      position: absolute;
      width: 2.6rem;
      right: .67rem;
      top: .32rem;
      height: 0.72rem;
      line-height: 0.72rem;
      text-align: center;
      font-size: 0.4rem;
      color: #FFFFFF;
      border-left: .03rem solid #fff;
      padding-left: .48rem;
      opacity: .8;
    }
  }
  .read-agreement{
    margin-top: .4rem;
    font-size: 0.35rem;
    color: #FFFFFF;
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: center;
    a{
      font-size: 0.35rem;
      color: #FFFFFF;
      text-decoration: underline;
    }
  }
}
</style>
